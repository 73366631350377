"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants = require("@/common/constants");
const json_constants = require("@/client/components/create/json");
const vue_1 = require("vue");
const Color_1 = require("@/common/Color");
const BoardName_1 = require("@/common/boards/BoardName");
const RandomBoardOption_1 = require("@/common/boards/RandomBoardOption");
const CorporationsFilter_vue_1 = require("@/client/components/create/CorporationsFilter.vue");
const PreludesFilter_vue_1 = require("@/client/components/create/PreludesFilter.vue");
const i18n_1 = require("@/client/directives/i18n");
const ColoniesFilter_vue_1 = require("@/client/components/create/ColoniesFilter.vue");
const CardsFilter_vue_1 = require("@/client/components/create/CardsFilter.vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const utils_1 = require("@/common/utils/utils");
const RandomMAOptionType_1 = require("@/common/ma/RandomMAOptionType");
const Types_1 = require("@/common/turmoil/Types");
const PreferencesIcon_vue_1 = require("@/client/components/PreferencesIcon.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const vueRoot_1 = require("@/client/components/vueRoot");
const REVISED_COUNT_ALGORITHM = false;
exports.default = vue_1.default.extend({
    name: 'CreateGameForm',
    data() {
        return {
            firstIndex: 1,
            playersCount: 1,
            players: [
                { name: '', color: Color_1.Color.RED, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.GREEN, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.YELLOW, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.BLUE, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.BLACK, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.PURPLE, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.ORANGE, beginner: false, handicap: 0, first: false },
                { name: '', color: Color_1.Color.PINK, beginner: false, handicap: 0, first: false },
            ],
            corporateEra: true,
            prelude: false,
            prelude2Expansion: false,
            draftVariant: true,
            initialDraft: false,
            randomMA: RandomMAOptionType_1.RandomMAOptionType.NONE,
            modularMA: false,
            randomFirstPlayer: true,
            showOtherPlayersVP: false,
            venusNext: false,
            colonies: false,
            showColoniesList: false,
            showCorporationList: false,
            showPreludesList: false,
            showBannedCards: false,
            showIncludedCards: false,
            turmoil: false,
            customColonies: [],
            customCorporations: [],
            customPreludes: [],
            bannedCards: [],
            includedCards: [],
            board: BoardName_1.BoardName.THARSIS,
            boards: [
                BoardName_1.BoardName.THARSIS,
                BoardName_1.BoardName.HELLAS,
                BoardName_1.BoardName.ELYSIUM,
                RandomBoardOption_1.RandomBoardOption.OFFICIAL,
                BoardName_1.BoardName.UTOPIA_PLANITIA,
                BoardName_1.BoardName.VASTITAS_BOREALIS_NOVUS,
                BoardName_1.BoardName.TERRA_CIMMERIA_NOVUS,
                BoardName_1.BoardName.ARABIA_TERRA,
                BoardName_1.BoardName.AMAZONIS,
                BoardName_1.BoardName.TERRA_CIMMERIA,
                BoardName_1.BoardName.VASTITAS_BOREALIS,
                RandomBoardOption_1.RandomBoardOption.ALL,
            ],
            seed: Math.random(),
            seededGame: false,
            solarPhaseOption: false,
            shuffleMapOption: false,
            promoCardsOption: false,
            communityCardsOption: false,
            aresExtension: false,
            politicalAgendasExtension: Types_1.AgendaStyle.STANDARD,
            moonExpansion: false,
            pathfindersExpansion: false,
            undoOption: false,
            showTimers: true,
            fastModeOption: false,
            removeNegativeGlobalEventsOption: false,
            includeVenusMA: true,
            includeFanMA: false,
            startingCorporations: 2,
            soloTR: false,
            clonedGameId: undefined,
            allOfficialExpansions: false,
            requiresVenusTrackCompletion: false,
            requiresMoonTrackCompletion: false,
            moonStandardProjectVariant: false,
            moonStandardProjectVariant1: false,
            altVenusBoard: false,
            escapeVelocityMode: false,
            escapeVelocityThreshold: constants.DEFAULT_ESCAPE_VELOCITY_THRESHOLD,
            escapeVelocityBonusSeconds: constants.DEFAULT_ESCAPE_VELOCITY_BONUS_SECONDS,
            escapeVelocityPeriod: constants.DEFAULT_ESCAPE_VELOCITY_PERIOD,
            escapeVelocityPenalty: constants.DEFAULT_ESCAPE_VELOCITY_PENALTY,
            twoCorpsVariant: false,
            ceoExtension: false,
            customCeos: [],
            startingCeos: 3,
            startingPreludes: 4,
            starWarsExpansion: false,
            underworldExpansion: false,
            preludeDraftVariant: undefined,
            preludeToggled: false,
            uploading: false,
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
        CardsFilter: CardsFilter_vue_1.default,
        ColoniesFilter: ColoniesFilter_vue_1.default,
        CorporationsFilter: CorporationsFilter_vue_1.default,
        PreludesFilter: PreludesFilter_vue_1.default,
        PreferencesIcon: PreferencesIcon_vue_1.default,
    },
    watch: {
        allOfficialExpansions(value) {
            this.corporateEra = value;
            this.prelude = value;
            this.venusNext = value;
            this.colonies = value;
            this.turmoil = value;
            this.promoCardsOption = value;
            this.solarPhaseOption = value;
        },
        venusNext(value) {
            this.solarPhaseOption = value;
        },
        turmoil(value) {
            if (value === false) {
                this.politicalAgendasExtension = Types_1.AgendaStyle.STANDARD;
            }
        },
        initialDraft(value) {
            if (value === true && this.preludeDraftVariant === undefined) {
                this.preludeDraftVariant = true;
            }
        },
        prelude(value) {
            if (value === true && this.preludeDraftVariant === undefined) {
                this.preludeDraftVariant = true;
            }
        },
        prelude2Expansion(value) {
            if (value === true && this.preludeToggled === false && this.uploading === false) {
                this.prelude = true;
                this.preludeToggled = true;
            }
        },
        playersCount(value) {
            if (value === 1) {
                this.corporateEra = true;
            }
        },
    },
    computed: {
        RandomBoardOption() {
            return RandomBoardOption_1.RandomBoardOption;
        },
        RandomMAOptionType() {
            return RandomMAOptionType_1.RandomMAOptionType;
        },
        constants() {
            return constants;
        },
        PLAYER_COLORS() {
            return Color_1.PLAYER_COLORS;
        },
    },
    methods: {
        async downloadSettings() {
            const serializedData = await this.serializeSettings();
            if (serializedData) {
                const a = document.createElement('a');
                const blob = new Blob([serializedData], { 'type': 'application/json' });
                a.href = window.URL.createObjectURL(blob);
                a.download = 'tm_settings.json';
                a.click();
            }
        },
        uploadSettings() {
            const refs = this.$refs;
            const file = refs.file.files !== null ? refs.file.files[0] : undefined;
            const reader = new FileReader();
            const component = this;
            reader.addEventListener('load', () => {
                const warnings = [];
                try {
                    const readerResults = reader.result;
                    if (typeof (readerResults) === 'string') {
                        this.uploading = true;
                        const results = JSON.parse(readerResults);
                        const players = results['players'];
                        const validationErrors = validatePlayers(players);
                        if (validationErrors.length > 0) {
                            throw new Error(validationErrors.join('\n'));
                        }
                        if (results.corporationsDraft !== undefined) {
                            warnings.push('Corporations draft is no longer available. Future versions might just raise an error, so edit your JSON file.');
                        }
                        const customCorporations = results[json_constants.CUSTOM_CORPORATIONS] || results[json_constants.OLD_CUSTOM_CORPORATIONS] || [];
                        const customColonies = results[json_constants.CUSTOM_COLONIES] || results[json_constants.OLD_CUSTOM_COLONIES] || [];
                        const bannedCards = results[json_constants.BANNED_CARDS] || results[json_constants.OLD_BANNED_CARDS] || [];
                        const includedCards = results[json_constants.INCLUDED_CARDS] || [];
                        const customPreludes = results[json_constants.CUSTOM_PRELUDES] || [];
                        component.playersCount = players.length;
                        component.showCorporationList = customCorporations.length > 0;
                        component.showColoniesList = customColonies.length > 0;
                        component.showBannedCards = bannedCards.length > 0;
                        component.showIncludedCards = includedCards.length > 0;
                        component.showPreludesList = customPreludes.length > 0;
                        const capturedSolarPhaseOption = results.solarPhaseOption;
                        const specialFields = [
                            json_constants.CUSTOM_CORPORATIONS,
                            json_constants.OLD_CUSTOM_CORPORATIONS,
                            json_constants.CUSTOM_COLONIES,
                            json_constants.OLD_CUSTOM_COLONIES,
                            json_constants.CUSTOM_PRELUDES,
                            json_constants.BANNED_CARDS,
                            json_constants.INCLUDED_CARDS,
                            json_constants.OLD_BANNED_CARDS,
                            'players',
                            'solarPhaseOption',
                            'constants'
                        ];
                        for (const k in results) {
                            if (specialFields.includes(k))
                                continue;
                            if (!Object.prototype.hasOwnProperty.call(component, k)) {
                                warnings.push('Unknown property: ' + k);
                            }
                            component[k] = results[k];
                        }
                        for (let i = 0; i < players.length; i++) {
                            component.players[i] = players[i];
                        }
                        vue_1.default.nextTick(() => {
                            try {
                                if (component.showColoniesList)
                                    refs.coloniesFilter.updateColoniesByNames(customColonies);
                                if (component.showCorporationList)
                                    refs.corporationsFilter.selectedCorporations = customCorporations;
                                if (component.showPreludesList)
                                    refs.preludesFilter.updatePreludes(customPreludes);
                                if (component.showBannedCards)
                                    refs.cardsFilter.selectedCardNames = bannedCards;
                                if (component.showIncludedCards)
                                    refs.cardsFilter2.selectedCardNames = includedCards;
                                if (!component.seededGame)
                                    component.seed = Math.random();
                                component.solarPhaseOption = Boolean(capturedSolarPhaseOption);
                                this.uploading = false;
                            }
                            catch (e) {
                                window.alert('Error reading JSON ' + e);
                            }
                        });
                    }
                    if (warnings.length > 0) {
                        window.alert('Settings loaded, with these warnings: \n' + warnings.join('\n'));
                    }
                    else {
                        window.alert('Settings loaded.');
                    }
                }
                catch (e) {
                    window.alert('Error loading settings ' + e);
                }
            }, false);
            if (file) {
                if (/\.json$/i.test(file.name)) {
                    reader.readAsText(file);
                }
            }
        },
        getPlayerNamePlaceholder(index) {
            return (0, i18n_1.translateTextWithParams)('Player ${0} name', [String(index + 1)]);
        },
        updatecustomCorporations(customCorporations) {
            this.customCorporations = customCorporations;
        },
        updateCustomPreludes(customPreludes) {
            this.customPreludes = customPreludes;
        },
        updateBannedCards(bannedCards) {
            this.bannedCards = bannedCards;
        },
        updateIncludedCards(includedCards) {
            this.includedCards = includedCards;
        },
        updatecustomColonies(customColonies) {
            this.customColonies = customColonies;
        },
        getPlayers() {
            return this.players.slice(0, this.playersCount);
        },
        isRandomMAEnabled() {
            return this.randomMA !== RandomMAOptionType_1.RandomMAOptionType.NONE;
        },
        randomMAToggle() {
            if (this.randomMA === RandomMAOptionType_1.RandomMAOptionType.NONE) {
                this.randomMA = RandomMAOptionType_1.RandomMAOptionType.LIMITED;
            }
            else {
                this.randomMA = RandomMAOptionType_1.RandomMAOptionType.NONE;
            }
        },
        getRandomMaOptionType(type) {
            if (type === 'limited') {
                return RandomMAOptionType_1.RandomMAOptionType.LIMITED;
            }
            else if (type === 'full') {
                return RandomMAOptionType_1.RandomMAOptionType.UNLIMITED;
            }
            else {
                return RandomMAOptionType_1.RandomMAOptionType.NONE;
            }
        },
        isPoliticalAgendasExtensionEnabled() {
            return this.politicalAgendasExtension !== Types_1.AgendaStyle.STANDARD;
        },
        politicalAgendasExtensionToggle() {
            if (this.politicalAgendasExtension === Types_1.AgendaStyle.STANDARD) {
                this.politicalAgendasExtension = Types_1.AgendaStyle.RANDOM;
            }
            else {
                this.politicalAgendasExtension = Types_1.AgendaStyle.STANDARD;
            }
        },
        getPoliticalAgendasExtensionAgendaStyle(type) {
            if (type === 'random') {
                return Types_1.AgendaStyle.RANDOM;
            }
            else if (type === 'chairman') {
                return Types_1.AgendaStyle.CHAIRMAN;
            }
            else {
                console.warn('AgendaStyle not found');
                return Types_1.AgendaStyle.STANDARD;
            }
        },
        isBeginnerToggleEnabled() {
            return !(this.initialDraft || this.prelude || this.venusNext || this.colonies || this.turmoil);
        },
        getPlayersCountText(count) {
            if (count === 1) {
                return (0, i18n_1.translateText)('Solo');
            }
            return count.toString();
        },
        deselectVenusCompletion() {
            if (this.venusNext === false) {
                this.requiresVenusTrackCompletion = false;
            }
        },
        deselectMoonCompletion() {
            if (this.moonExpansion === false) {
                this.requiresMoonTrackCompletion = false;
                this.moonStandardProjectVariant = false;
                this.moonStandardProjectVariant1 = false;
            }
        },
        getBoardColorClass(boardName) {
            switch (boardName) {
                case BoardName_1.BoardName.THARSIS:
                    return 'create-game-board-hexagon create-game-tharsis';
                case BoardName_1.BoardName.HELLAS:
                    return 'create-game-board-hexagon create-game-hellas';
                case BoardName_1.BoardName.ELYSIUM:
                    return 'create-game-board-hexagon create-game-elysium';
                case BoardName_1.BoardName.UTOPIA_PLANITIA:
                    return 'create-game-board-hexagon create-game-utopia-planitia';
                case BoardName_1.BoardName.VASTITAS_BOREALIS_NOVUS:
                    return 'create-game-board-hexagon create-game-vastital-borealis-novus';
                case BoardName_1.BoardName.AMAZONIS:
                    return 'create-game-board-hexagon create-game-amazonis';
                case BoardName_1.BoardName.ARABIA_TERRA:
                    return 'create-game-board-hexagon create-game-arabia-terra';
                case BoardName_1.BoardName.TERRA_CIMMERIA:
                    return 'create-game-board-hexagon create-game-terra-cimmeria';
                case BoardName_1.BoardName.VASTITAS_BOREALIS:
                    return 'create-game-board-hexagon create-game-vastitas-borealis';
                default:
                    return 'create-game-board-hexagon create-game-random';
            }
        },
        getPlayerCubeColorClass(color) {
            return (0, utils_1.playerColorClass)(color, 'bg');
        },
        getPlayerContainerColorClass(color) {
            return (0, utils_1.playerColorClass)(color, 'bg_transparent');
        },
        isEnabled(module) {
            const model = this;
            switch (module) {
                case 'base': return true;
                case 'corpera': return model.corporateEra;
                case 'promo': return model.promoCardsOption;
                case 'venus': return model.venusNext;
                case 'colonies': return model.colonies;
                case 'prelude': return model.prelude;
                case 'prelude2': return model.prelude2Expansion;
                case 'turmoil': return model.turmoil;
                case 'community': return model.communityCardsOption;
                case 'ares': return model.aresExtension;
                case 'moon': return model.moonExpansion;
                case 'pathfinders': return model.pathfindersExpansion;
                case 'ceo': return model.ceoExtension;
                case 'starwars': return model.starWarsExpansion;
                case 'underworld': return model.underworldExpansion;
                default: throw new Error('Unknown module: ' + module);
            }
        },
        boardHref(boardName) {
            const options = {
                [BoardName_1.BoardName.THARSIS]: 'tharsis',
                [BoardName_1.BoardName.HELLAS]: 'hellas',
                [BoardName_1.BoardName.ELYSIUM]: 'elysium',
                [BoardName_1.BoardName.ARABIA_TERRA]: 'arabia-terra',
                [BoardName_1.BoardName.UTOPIA_PLANITIA]: 'utopia-planitia',
                [BoardName_1.BoardName.VASTITAS_BOREALIS_NOVUS]: 'vastitas-borealis-novus',
                [BoardName_1.BoardName.VASTITAS_BOREALIS]: 'vastitas-borealis',
                [BoardName_1.BoardName.AMAZONIS]: 'amazonis-planatia',
                [BoardName_1.BoardName.TERRA_CIMMERIA]: 'terra-cimmeria',
                [BoardName_1.BoardName.TERRA_CIMMERIA_NOVUS]: 'terra-cimmeria-novus',
                [RandomBoardOption_1.RandomBoardOption.OFFICIAL]: '',
                [RandomBoardOption_1.RandomBoardOption.ALL]: '',
            };
            return 'https://github.com/terraforming-mars/terraforming-mars/wiki/Maps#' + options[boardName];
        },
        async serializeSettings() {
            let players = this.players.slice(0, this.playersCount);
            if (this.randomFirstPlayer) {
                players = players.map((a) => ({ sort: Math.random(), value: a }))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value);
                this.firstIndex = Math.floor(this.seed * this.playersCount) + 1;
            }
            const uniqueColors = new Set(players.map((player) => player.color));
            if (uniqueColors.size !== players.length) {
                const usedColors = new Set();
                const unusedColors = Color_1.PLAYER_COLORS.filter((c) => !uniqueColors.has(c));
                for (const player of players) {
                    const color = player.color;
                    if (usedColors.has(color)) {
                        player.color = unusedColors.shift();
                        usedColors.add(color);
                    }
                    else {
                        usedColors.add(color);
                    }
                }
            }
            const isSoloMode = this.playersCount === 1;
            this.players.forEach((player) => {
                if (player.name === '') {
                    if (isSoloMode) {
                        player.name = this.$t('You');
                    }
                    else {
                        const defaultPlayerName = this.$t(player.color.charAt(0).toUpperCase() + player.color.slice(1));
                        player.name = defaultPlayerName;
                    }
                }
            });
            players.map((player) => {
                player.first = (this.firstIndex === player.index);
                return player;
            });
            const corporateEra = this.corporateEra;
            const prelude = this.prelude;
            const prelude2Expansion = this.prelude2Expansion;
            const draftVariant = this.draftVariant;
            const initialDraft = this.initialDraft;
            const randomMA = this.randomMA;
            const showOtherPlayersVP = this.showOtherPlayersVP;
            const venusNext = this.venusNext;
            const colonies = this.colonies;
            const turmoil = this.turmoil;
            const solarPhaseOption = this.solarPhaseOption;
            const shuffleMapOption = this.shuffleMapOption;
            const customColonies = this.customColonies;
            const customCorporations = this.customCorporations;
            const customPreludes = this.customPreludes;
            const bannedCards = this.bannedCards;
            const includedCards = this.includedCards;
            const board = this.board;
            const seed = this.seed;
            const promoCardsOption = this.promoCardsOption;
            const communityCardsOption = this.communityCardsOption;
            const aresExtension = this.aresExtension;
            const politicalAgendasExtension = this.politicalAgendasExtension;
            const moonExpansion = this.moonExpansion;
            const pathfindersExpansion = this.pathfindersExpansion;
            const undoOption = this.undoOption;
            const showTimers = this.showTimers;
            const fastModeOption = this.fastModeOption;
            const removeNegativeGlobalEventsOption = this.removeNegativeGlobalEventsOption;
            const includeVenusMA = this.includeVenusMA;
            const includeFanMA = this.includeFanMA;
            const startingCorporations = this.startingCorporations;
            const soloTR = this.soloTR;
            const randomFirstPlayer = this.randomFirstPlayer;
            const requiresVenusTrackCompletion = this.requiresVenusTrackCompletion;
            const escapeVelocityMode = this.escapeVelocityMode;
            const escapeVelocityThreshold = this.escapeVelocityMode ? this.escapeVelocityThreshold : undefined;
            const escapeVelocityBonusSeconds = this.escapeVelocityBonusSeconds ? this.escapeVelocityBonusSeconds : undefined;
            const escapeVelocityPeriod = this.escapeVelocityMode ? this.escapeVelocityPeriod : undefined;
            const escapeVelocityPenalty = this.escapeVelocityMode ? this.escapeVelocityPenalty : undefined;
            const twoCorpsVariant = this.twoCorpsVariant;
            const ceoExtension = this.ceoExtension;
            const customCeos = this.customCeos;
            const startingCeos = this.startingCeos;
            const startingPreludes = this.startingPreludes;
            let clonedGamedId = undefined;
            if (customColonies.length > 0) {
                const playersCount = players.length;
                let neededColoniesCount = playersCount + 2;
                if (playersCount === 1) {
                    neededColoniesCount = 4;
                }
                else if (playersCount === 2) {
                    neededColoniesCount = 5;
                }
                if (customColonies.length < neededColoniesCount) {
                    window.alert((0, i18n_1.translateTextWithParams)('Must select at least ${0} colonies', [neededColoniesCount.toString()]));
                    return;
                }
            }
            if (players.length === 1 && corporateEra === false) {
                const confirm = window.confirm((0, i18n_1.translateText)('We do not recommend playing a solo game without the Corporate Era. Press OK if you want to play without it.'));
                if (confirm === false)
                    return;
            }
            if (this.showCorporationList && customCorporations.length > 0) {
                let neededCorpsCount = players.length * startingCorporations;
                if (REVISED_COUNT_ALGORITHM) {
                    if (this.twoCorpsVariant) {
                        neededCorpsCount = (players.length * startingCorporations) + (players.length * 4);
                    }
                    else {
                        neededCorpsCount = players.length * startingCorporations;
                        if (this.prelude && this.promoCardsOption)
                            neededCorpsCount += 4;
                    }
                }
                if (customCorporations.length < neededCorpsCount) {
                    window.alert((0, i18n_1.translateTextWithParams)('Must select at least ${0} corporations', [neededCorpsCount.toString()]));
                    return;
                }
                let valid = true;
                for (const corp of customCorporations) {
                    const card = (0, ClientCardManifest_1.getCard)(corp);
                    for (const module of card?.compatibility ?? []) {
                        if (!this.isEnabled(module)) {
                            valid = false;
                        }
                    }
                }
                if (valid === false) {
                    const confirm = window.confirm((0, i18n_1.translateText)('Some of the corps you selected need expansions you have not enabled. Using them might break your game. Press OK to continue or Cancel to change your selections.'));
                    if (confirm === false)
                        return;
                }
            }
            else {
                customCorporations.length = 0;
            }
            if (this.showPreludesList && customPreludes.length > 0) {
                const requiredPreludeCount = players.length * startingPreludes;
                if (customPreludes.length < requiredPreludeCount) {
                    window.alert((0, i18n_1.translateTextWithParams)('Must select at least ${0} Preludes', [requiredPreludeCount.toString()]));
                    return;
                }
                let valid = true;
                for (const prelude of customPreludes) {
                    const card = (0, ClientCardManifest_1.getCard)(prelude);
                    for (const module of card?.compatibility ?? []) {
                        if (!this.isEnabled(module)) {
                            valid = false;
                        }
                    }
                }
                if (valid === false) {
                    const confirm = window.confirm((0, i18n_1.translateText)('Some of the Preludes you selected need expansions you have not enabled. Using them might break your game. Press OK to continue or Cancel to change your selections.'));
                    if (confirm === false)
                        return;
                }
            }
            else {
                customPreludes.length = 0;
            }
            if (this.clonedGameId !== undefined && this.seededGame) {
                const gameData = await fetch('api/cloneablegame?id=' + this.clonedGameId)
                    .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    if (response.status === 404) {
                        return;
                    }
                    return response.text().then((res) => new Error(res));
                });
                if (gameData === undefined) {
                    alert(this.$t('Game id ' + this.clonedGameId + ' not found'));
                    return;
                }
                if (gameData instanceof Error) {
                    alert(this.$t('Error looking for predefined game ' + gameData.message));
                    return;
                }
                clonedGamedId = this.clonedGameId;
                if (gameData.playerCount !== players.length) {
                    alert(this.$t('Player count mismatch'));
                    this.playersCount = gameData.playerCount;
                    return;
                }
            }
            else if (!this.seededGame) {
                clonedGamedId = undefined;
            }
            const dataToSend = {
                players,
                corporateEra,
                prelude,
                prelude2Expansion,
                draftVariant,
                showOtherPlayersVP,
                venusNext,
                colonies,
                turmoil,
                customCorporationsList: customCorporations,
                customColoniesList: customColonies,
                customPreludes,
                bannedCards,
                includedCards,
                board,
                seed,
                solarPhaseOption,
                promoCardsOption,
                communityCardsOption,
                aresExtension: aresExtension,
                politicalAgendasExtension: politicalAgendasExtension,
                moonExpansion: moonExpansion,
                pathfindersExpansion: pathfindersExpansion,
                undoOption,
                showTimers,
                fastModeOption,
                removeNegativeGlobalEventsOption,
                includeVenusMA,
                includeFanMA,
                modularMA: this.modularMA,
                startingCorporations,
                soloTR,
                clonedGamedId,
                initialDraft,
                preludeDraftVariant: this.preludeDraftVariant ?? false,
                randomMA,
                shuffleMapOption,
                randomFirstPlayer,
                requiresVenusTrackCompletion,
                requiresMoonTrackCompletion: this.requiresMoonTrackCompletion,
                moonStandardProjectVariant: this.moonStandardProjectVariant,
                moonStandardProjectVariant1: this.moonStandardProjectVariant1,
                altVenusBoard: this.altVenusBoard,
                escapeVelocityMode,
                escapeVelocityThreshold,
                escapeVelocityBonusSeconds,
                escapeVelocityPeriod,
                escapeVelocityPenalty,
                twoCorpsVariant,
                ceoExtension,
                customCeos,
                startingCeos,
                startingPreludes,
                starWarsExpansion: this.starWarsExpansion,
                underworldExpansion: this.underworldExpansion,
            };
            return JSON.stringify(dataToSend, undefined, 4);
        },
        async createGame() {
            const dataToSend = await this.serializeSettings();
            if (dataToSend === undefined)
                return;
            const onSuccess = (json) => {
                if (json.players.length === 1) {
                    window.location.href = 'player?id=' + json.players[0].id;
                    return;
                }
                else {
                    window.history.replaceState(json, `${constants.APP_NAME} - Game`, 'game?id=' + json.id);
                    (0, vueRoot_1.vueRoot)(this).game = json;
                    (0, vueRoot_1.vueRoot)(this).screen = 'game-home';
                }
            };
            fetch('game', { 'method': 'PUT', 'body': dataToSend, 'headers': { 'Content-Type': 'application/json' } })
                .then((response) => response.text())
                .then((text) => {
                try {
                    const json = JSON.parse(text);
                    onSuccess(json);
                }
                catch (err) {
                    throw new Error(text);
                }
            })
                .catch((error) => {
                alert(error.message);
            });
        },
    },
});
function validatePlayers(players) {
    const errors = [];
    const colors = new Set(players.map((p) => p.color));
    for (const color of colors) {
        if (Color_1.PLAYER_COLORS.indexOf(color) === -1) {
            errors.push(color + ' is not a color');
        }
    }
    if (colors.size !== players.length) {
        errors.push('Colors are duplicated');
    }
    return errors;
}
